import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  submitted = false;
  message:any;

  adminLoginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength]],
  });

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  get loginControls() {
    return this.adminLoginForm.controls;
  }

  submitLogin() {
    this.submitted = true;
    let data = this.adminLoginForm.value;
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // console.log(data);
    if (this.adminLoginForm.valid) {
      this.http.post(this.global.apiUrl + '/auth/adminSignIn', data, { headers: header }).subscribe(
        (res: any) => {
          console.log(res);
          if (res.message != "Unauthorized") {
            localStorage.setItem('admin_access_token', res.access_token);
            localStorage.setItem('user_type', res.admin.user_type)
            // localStorage.setItem('admin_user_id', res.user.id);
            let name = res.admin.name;
            if (res.admin.user_type == 'admin') {
              // console.log(' admin true')
              this.message  = name + ' login successfully.';
              localStorage.setItem('admin_id', res.admin.id);
              this.router.navigateByUrl('/admin/dashboard');
              
              this.snackBar.open(this.message, 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
              });
            }
            else {
              console.log('support agent true')
              this.message  = name + ' login successfully.';
              localStorage.setItem('support_id', res.admin.id);
              this.router.navigateByUrl('/support-agent/dashboard');
              this.snackBar.open(this.message, 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
              });
            }
            // this.router.navigateByUrl('/admin/dashboard');
            // this.snackBar.open('Admin login successfully.', 'X', {
            //   duration: 3000,
            //   verticalPosition: 'top',
            //   horizontalPosition: 'right',
            // });
          } else {
            this.snackBar.open('Enter correct credentials.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['error_snackBar']
            });
          }
        }, (err => {
          // console.log(err)
          this.snackBar.open('Enter correct credentials.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error_snackBar']
          });
        })
      )
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductImagesCropperComponent } from 'src/app/component/product-images-cropper/product-images-cropper.component';

@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss']
})
export class EditCardComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;
  @ViewChild('change_photo', { static: false }) changePhotos: ElementRef;
  public id: string;

  infoFormGroup: FormGroup;
  socialFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  templateId;
  templateInfo: any = {};
  loading = false;
  color;
  invite_code: any;
  PackageInfo: any;
  videoLimit = 1;
  changePhotoFile: File | null = null; 
  changeLogoFile: File | null = null; 
  changeProductImagesFile: File[] = [];
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);
    if (this.id != null) {
      this.templateId = 1;
      this.getCard(this.id);
    }
    this.infoFormGroup = this.fb.group({
      templateId: '',
      cardTitle: ['', Validators.required],
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'team@goproliftbrand.com',
      phoneNumber: '99122301',
      alternativePhoneNo: '',
      companyName: 'ProLift Rigging Co.',
      jobTitle: 'Affiliate',
      address: 'ProLift Rigging Co. team',
      aboutText: "Thanks for checking out my virtual business card! I’m excited to introduce you to ProLift Rigging Co. because I know you'll enjoy it as much as I have. ProLift Rigging Co. helps me keep track of my prospects, my team, and my time so I can get more accomplished every day. Feel freeto contact me with any questions.",
      phoneTextAllow: true,
      showSaveButton: true,
      showForwardButton: true,
      showInviteCode: true,
      inviteCode: ''
    });

    this.socialFormGroup = this.fb.group({
      facebook: 'https://www.facebook.com/',
      twitter: 'https://www.twitter.com/',
      youtube: 'https://www.youtube.com/',
      instagram: 'https://www.instagram.com/',
      linkedin: 'https://www.linkedin.com/',
      pinterest: 'https://www.pinterest.com/',
      skypeID: '',
      whatsappID: '',
      snapchat: '',
      lineID: '',
      voxerID: '',
      youtubeVideos: this.fb.array([
        this.fb.group({
          youtubeTitle: '',
          youtubeLink: ''
          // youtubeTitle: '',
          // youtubeLink: 'https://www.youtube.com/embed/soXhe4aEsTU'
        })
      ]),
      vimeoVideos: this.fb.array([
        this.fb.group({
          vimeoTitle: '',
          vimeoLink: ''
        })
      ]),
      linkButtons: this.fb.array([
        this.fb.group({
          linkButtonTitle: 'Learn More',
          websiteLink: 'https://goshufflepro.com/'
        })
      ])
    });

    this.fourFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });

    this.templateInfo.infoFormData = this.infoFormGroup.value;
    this.templateInfo.socialFormData = this.socialFormGroup.value;
  }

  ngOnInit() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl + '/userData', { user_id: localStorage.getItem('user_id') }, { headers: header }).subscribe(
      (res: any) => {

        console.log(res);
        console.log(res.Users.refer_code);
        this.invite_code = res.Users.refer_code;
        this.PackageInfo = res.Users.PackageData;
        console.log('Package info =>', this.PackageInfo);
        this.videoLimit = (this.PackageInfo.videos === '1 video link') ? 1 : 
                           (this.PackageInfo.videos === '2 video link') ? 2 :
                           (this.PackageInfo.videos === '3 video link') ? 3 : 10; 
        // localStorage.setItem('invite_Code',res.Users.refer_code);
        this.templateInfo.infoFormData.inviteCode = this.invite_code;
        console.log(this.invite_code);

      }, (err => {
        console.log(err)

      })
    )
    // this.id = this.route.snapshot.paramMap.get('id');
    // // console.log(this.id);
    // if(this.id != null){
    //   this.templateId = 1;
    //   this.getCard(this.id);
    // }
    // this.infoFormGroup = this.fb.group({
    //   templateId: '',
    //   cardTitle: ['', Validators.required],
    //   firstName: 'Jane',
    //   lastName: 'Doe',
    //   email: 'team@goProLift Rigging Co..com',
    //   phoneNumber: '99122301',
    //   alternativePhoneNo: '',
    //   companyName: 'ProLift Rigging Co.',
    //   jobTitle: 'Affiliate',
    //   address: 'ProLift Rigging Co. team',
    //   aboutText: "Thanks for checking out my virtual business card! I’m excited to introduce you to ProLift Rigging Co. because I know you'll enjoy it as much as I have. ProLift Rigging Co. helps me keep track of my prospects, my team, and my time so I can get more accomplished every day. Feel freeto contact me with any questions.",
    //   phoneTextAllow: true,
    //   showSaveButton: true,
    //   showForwardButton: true,
    //   showInviteCode: true,
    //   inviteCode: ''
    // });

    // this.socialFormGroup = this.fb.group({
    //   facebook: 'https://www.facebook.com/',
    //   twitter: 'https://www.twitter.com/',
    //   youtube: 'https://www.youtube.com/',
    //   instagram: 'https://www.instagram.com/',
    //   linkedin: 'https://www.linkedin.com/',
    //   pinterest: 'https://www.pinterest.com/',
    //   skypeID: '',
    //   whatsappID: '',
    //   snapchat: '',
    //   lineID: '',
    //   voxerID: '',
    //   youtubeVideos: this.fb.array([
    //     this.fb.group({
    //       youtubeTitle: '',
    //       youtubeLink: 'https://www.youtube.com/embed/soXhe4aEsTU'
    //     })
    //   ]),
    //   vimeoVideos: this.fb.array([
    //     this.fb.group({
    //       vimeoTitle: '',
    //       vimeoLink: ''
    //     })
    //   ]),
    //   linkButtons: this.fb.array([
    //     this.fb.group({
    //       linkButtonTitle: 'Learn More',
    //       websiteLink: 'https://goshufflepro.com/'
    //     })
    //   ])
    // });

    // this.fourFormGroup = this.fb.group({
    //   secondCtrl: ['', Validators.required]
    // });

    // this.templateInfo.infoFormData = this.infoFormGroup.value;
    // this.templateInfo.socialFormData = this.socialFormGroup.value;
    // this.templateInfo.infoFormData.inviteCode = localStorage.getItem('invite_Code');

  }

  get youtubeVideosArr() { return <FormArray>this.socialFormGroup.get('youtubeVideos'); }
  get vimeoVideosArr() { return <FormArray>this.socialFormGroup.get('vimeoVideos'); }
  get linkButtonsArr() { return <FormArray>this.socialFormGroup.get('linkButtons'); }

  addYoutubeVideos() {
    let control = <FormArray>this.socialFormGroup.controls.youtubeVideos;
    control.push(
      this.fb.group({
        youtubeTitle: "",
        youtubeLink: ""
      })
    );
  }

  addVimeoVideos() {
    let control = <FormArray>this.socialFormGroup.controls.vimeoVideos;
    control.push(
      this.fb.group({
        vimeoTitle: "",
        vimeoLink: ""
      })
    );
  }

  addButtons() {
    let control = <FormArray>this.socialFormGroup.controls.linkButtons;
    control.push(
      this.fb.group({
        linkButtonTitle: "",
        websiteLink: ""
      })
    );
  }

  removeItem(control, i) {
    control.removeAt(i);
    this.templateInfo.socialFormData = this.socialFormGroup.value;
  }

  templateSelcted() {
    if (this.templateId != null) {
      this.stepper.next();
    } else {
      this.snackBar.open('Please, select template.', 'X', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['error_snackBar']
      });
    }
  }

  checkInfoValidation() {
    if (this.infoFormGroup.invalid) {
      this.snackBar.open('Please, Fill the mandatory field.', 'X', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['error_snackBar']
      });
    } else {
      this.stepper.next();
    }
  }

  valueChange() {
    // console.log(this.socialFormGroup.value.youtubeVideos);
    let length = this.socialFormGroup.value.youtubeVideos.length;
    for (let i = 0; i < length; i++) {
      let link = this.socialFormGroup.value.youtubeVideos[i].youtubeLink;
      // console.log(link);
      let str: any = [] = link.split('v=');

      if (str.length == 1) {
        // console.log(str[0]);
        let you_link = str[0];
        this.socialFormGroup.value.youtubeVideos[i].youtubeLink = you_link;
      }
      else {
        // console.log(str[0], str[1]);
        let you_link = 'https://www.youtube.com/embed/' + str[1];
        this.socialFormGroup.value.youtubeVideos[i].youtubeLink = you_link;
      }
      // console.log(str);
    }

    this.templateInfo.infoFormData = this.infoFormGroup.value;
    this.templateInfo.socialFormData = this.socialFormGroup.value;
    // console.log(this.templateInfo);
  }

  changeButtonColor(color) {
    this.templateInfo.buttonColor = color;
  }

  // modals
  openSelectTemplateModal() {
    let dialogRef = this.dialog.open(SelectTemplateModalComponent);

    dialogRef.afterClosed().subscribe(
      data => {
        this.templateId = data;
      }
    )
  }

  openImageProductCropperModal() {
    // console.log('product')
    let dialogRef = this.dialog.open(ProductImagesCropperComponent, {
      width: '100vh',
      data: this.PackageInfo.product_image
    });

    dialogRef.afterClosed().subscribe(
      image => {
        // console.log(str);
        // console.log(image);
        if (image != '' || image != ' ' || image != undefined) {
          // console.log('image');
          this.templateInfo.changeProductImages = image.croppedImages;
          this.changeProductImagesFile = image.croppedImagesFile;
        }
        // if(str === 'changePhoto'){
        //   this.templateInfo.change_photo = image;
        //   // console.log('changePhoto');
        // }else if(str === 'changeLogo'){
        //   this.templateInfo.change_logo = image;
        //   // console.log('changeLogo');
        // }else{
        //   this.templateInfo.changeProductImages = image;
        //   // console.log('else');
      }
      // console.log(this.templateInfo)
    )
  }

  openImageCropperModal(str) {
    let dialogRef = this.dialog.open(ImageCropperModalComponent, {
      width: '100vh',
    });

    dialogRef.afterClosed().subscribe(
      image => {
        // console.log(str);
        // console.log(image);
        if(str === 'changePhoto'){
          this.templateInfo.change_photo = image.croppedImage;
          this.changePhotoFile = image.croppedImageFile;
          // console.log('changePhoto');
        }else if(str === 'changeLogo'){
          this.templateInfo.change_logo = image.croppedImage;
          this.changeLogoFile = image.croppedImageFile;
          // console.log('changeLogo');
        }
        // console.log(this.templateInfo)
      }
    )
  }

  creatCard() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let data = this.templateInfo;
    data.user_id = localStorage.getItem('user_id');
    data.infoFormData = JSON.stringify(this.infoFormGroup.value);
    data.socialFormData = JSON.stringify(this.socialFormGroup.value);
    data.changeProductImages = JSON.stringify(this.templateInfo.changeProductImages);
    // console.log(this.templateInfo.changeProductImages)
    // console.log(data)
    data.colorTheme = this.templateInfo.buttonColor;
    this.http.post(this.global.apiUrl + '/saveCard', data, { headers: header }).subscribe(
      (res: any) => {
        this.router.navigateByUrl('/cards');
        this.snackBar.open('Card created successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
        });
      }, (err => {
        // console.log(err)
        this.router.navigateByUrl('/cards');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  getCard(id) {
    this.loading = true;

    // console.log('get')
    // console.log(id)
    // this.socialFormGroup.controls.youtubeVideos
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let data = {
      user_id: localStorage.getItem('user_id'),
      card_id: id
    };
    this.http.post(this.global.apiUrl + '/getSingleCard', data, { headers: header }).subscribe(
      (res: any) => {
        // console.log(res)
        // console.log(res.Card.socialFormFormData.youtubeVideos);
        this.loading = false;
        this.infoFormGroup.patchValue(res.Card.infoFormData);
        this.socialFormGroup.patchValue(res.Card.socialFormData);
        this.changePhotos = res.Card.change_photo;
        this.templateInfo.infoFormData = res.Card.infoFormData;
        this.templateInfo.socialFormData = res.Card.socialFormData;
        this.templateInfo.change_photo = res.Card.change_photo;
        this.templateInfo.change_logo = res.Card.change_logo;
        this.templateInfo.changeProductImages = JSON.parse(res.Card.changeProductImages);
        this.templateInfo.buttonColor = res.Card.colorTheme;
        this.templateId = res.Card.infoFormData.templateId;

        
        
        let control_youtube = <FormArray>this.socialFormGroup.controls.youtubeVideos;
        let control_link = <FormArray>this.socialFormGroup.controls.linkButtons;
        let control_vimeo = <FormArray>this.socialFormGroup.controls.vimeoVideos;
        control_youtube.removeAt(0);
        control_link.removeAt(0);
        control_vimeo.removeAt(0);
        for (let item of res.Card.socialFormData.youtubeVideos) {
          // console.log(item);
          control_youtube.push(
            this.fb.group({
              youtubeTitle: item.youtubeTitle,
              youtubeLink: item.youtubeLink
            })
          );
        }

        for (let item of res.Card.socialFormData.vimeoVideos) {
          // console.log(item);
          control_vimeo.push(
            this.fb.group({
              vimeoTitle: item.vimeoTitle,
              vimeoLink: item.vimeoLink
            })
          );
        }
        for (let item of res.Card.socialFormData.linkButtons) {
          // console.log(item);
          control_link.push(
            this.fb.group({
              linkButtonTitle: item.linkButtonTitle,
              websiteLink: item.websiteLink
            })
          );
        }

      }, (err => {
        this.loading = false;
        // console.log(err)
        this.router.navigateByUrl('/cards');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  oldEditCard() {
    this.loading = true;
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let data = this.templateInfo;
    data.user_id = localStorage.getItem('user_id');
    data.card_id = this.id;
    data.infoFormData = JSON.stringify(this.infoFormGroup.value);
    data.socialFormData = JSON.stringify(this.socialFormGroup.value);
    data.changeProductImages = JSON.stringify(this.templateInfo.changeProductImages);
    // console.log(this.templateInfo.changeProductImages)
    // console.log(data)
    data.colorTheme = this.templateInfo.buttonColor;
    this.http.post(this.global.apiUrl + '/updateCard', data, { headers: header }).subscribe(
      (res: any) => {
        // console.log(res)
        this.loading = false;
        this.router.navigateByUrl('/cards');
        this.snackBar.open('Card edited successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
        });
        this.templateInfo = [];
      }, (err => {
        this.loading = false;
        // console.log(err)
        this.router.navigateByUrl('/cards');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }
  editCard() {
    // Create headers without Content-Type as FormData will automatically set it to multipart/form-data
    const header = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
  
    // Prepare the data to be sent in the API request
    let data = this.templateInfo;
    data.user_id = localStorage.getItem('user_id');
    data.card_id = this.id;
    data.infoFormData = JSON.stringify(this.infoFormGroup.value);
    data.socialFormData = JSON.stringify(this.socialFormGroup.value);
    data.colorTheme = this.templateInfo.buttonColor;
  
    // Delete unwanted properties from data
    delete data.change_photo;
    delete data.change_logo;
    delete data.changeProductImages;
  
    // Create a new FormData instance to send the file
    const formData = new FormData();
  
    // Append non-file data to FormData
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  
    // Append files for changeProductImagesFile array if any
    if (this.changeProductImagesFile.length) {
      for (const key in this.changeProductImagesFile) {
        if (this.changeProductImagesFile[key]) {
          formData.append('changeProductImages[]', this.changeProductImagesFile[key]);
        }
      }
    }
  
    // Append the file (change photo) if it exists
    if (this.changePhotoFile) {
      formData.append('change_photo', this.changePhotoFile);
    }
  
    // Append the file (change logo) if it exists
    if (this.changeLogoFile) {
      formData.append('change_logo', this.changeLogoFile);
    }
  
    // Show loading spinner (assuming it's being controlled)
    this.loading = true;
  
    // Make the POST request with FormData
    this.http.post(this.global.apiUrl + '/updateCard', formData, { headers: header }).subscribe(
      (res: any) => {
        this.loading = false; // Turn off loading spinner
        this.router.navigateByUrl('/cards');
  
        // Check for response message and display appropriate snackbar
        if (res.message === "You exceeds your cards limit") {
          this.snackBar.open(res.message, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error_snackBar']
          });
        } else {
          this.snackBar.open('Card edited successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
          });
        }
  
        // Clear template info after successful API call
        this.templateInfo = [];
      },
      (err) => {
        this.loading = false; // Turn off loading spinner
        this.router.navigateByUrl('/cards');
  
        // const errorMessage = err?.error?.message || 'Sorry! something went wrong.';
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-applied-user-list',
  templateUrl: './admin-applied-user-list.component.html',
  styleUrls: ['./admin-applied-user-list.component.scss']
})
export class AdminAppliedUserListComponent implements OnInit {


  displayedColumns: string[] = ['name', 'email','phone', 'action'];
  dataSource : any = [];
  loading = false;
  referal_code;

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getReferralAppliedUsers()
  }

  getReferralAppliedUsers(){
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });

    // console.log(data);
    this.http.post(this.global.apiUrl+'/referalAppliedUsersList',{}, {headers: header}).subscribe(
      (res : any) => {
          // console.log(res);
          this.dataSource = res.Refferal;
          this.loading = false;
        },(err => {
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sendCode(referralData){
    // console.log(referralData.id)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      referal_id: referralData.id
    }

    // console.log(data);

    this.http.post(this.global.apiUrl+'/createRefferalCode', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.dataSource = this.dataSource.filter(element => element.user_id !== referralData.user_id);
        this.snackBar.open('Refferal created and sent to user successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        // console.log(this.allCards)
        },(err => {
          // console.log(err)
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          this.router.navigateByUrl('/admin/dashboard');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-select-template-modal',
  templateUrl: './select-template-modal.component.html',
  styleUrls: ['./select-template-modal.component.scss']
})
export class SelectTemplateModalComponent implements OnInit {
  config  : SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    slideToClickedSlide: true,
    loop: true,
    // simulateTouch: true,
    // spaceBetween: 15,
     navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  modalTemplateImages = [
    {id: 1, templateImgUrl : "assets/images/template-images/slide-1.jpg"},
    {id: 2, templateImgUrl : "assets/images/template-images/slide-2.jpg"},
    {id: 3, templateImgUrl : "assets/images/template-images/slide-3.jpg"},
    {id: 4, templateImgUrl : "assets/images/template-images/slide-4.jpg"},
    {id: 5, templateImgUrl : "assets/images/template-images/slide-5.jpg"},
    {id: 6, templateImgUrl : "assets/images/template-images/slide-6.jpg"},
    {id: 7, templateImgUrl : "assets/images/template-images/slide-7.jpg"},
    {id: 8, templateImgUrl : "assets/images/template-images/slide-8.jpg"},
    {id: 9, templateImgUrl : "assets/images/template-images/slide-9.jpg"},
    {id: 10, templateImgUrl : "assets/images/template-images/slide-10.jpg"},
    {id: 11, templateImgUrl : "assets/images/template-images/slide-11.jpg"},
    {id: 12, templateImgUrl : "assets/images/template-images/slide-12.jpg"},
    {id: 13, templateImgUrl : "assets/images/template-images/slide-13.jpg"},
    {id: 14, templateImgUrl : "assets/images/template-images/slide-14.jpg"},
    {id: 15, templateImgUrl : "assets/images/template-images/slide-15.jpg"},
    {id: 16, templateImgUrl : "assets/images/template-images/slide-16.jpg"},
    // {templateImgUrl : "", display: "none"},
    {templateImgUrl : "", display: "none"},
    {templateImgUrl : "",  display: "none"}
  ];

  constructor( private dialogRef: MatDialogRef<SelectTemplateModalComponent>,
    private navigation: Router
    ) {
      
    }

  ngOnInit() { }

  selectTemplate(id){
    console.log(id);

    this.dialogRef.close(id);
  }
  
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-admin-support-add',
  templateUrl: './admin-support-add.component.html',
  styleUrls: ['./admin-support-add.component.scss']
})
export class AdminSupportAddComponent implements OnInit {

  loading;

  supportAgent = this.fb.group({
    name: [''],
    email: [''],
    password: ['']
  });

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    
  }

  submitSupportAgent(){
    console.log(this.supportAgent.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
  
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
  
   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/addSupportAgent', this.supportAgent.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/admin/admin-support-agent-list');
          this.snackBar.open('Support Agent added successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }


}

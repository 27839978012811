import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-support-list',
  templateUrl: './admin-support-list.component.html',
  styleUrls: ['./admin-support-list.component.scss']
})
export class AdminSupportListComponent implements OnInit {
  displayedColumns: string[] = ['index', 'name', 'email', 'action'];
  dataSource : any = [];
  loading = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getPacakges();
  }

  getPacakges(){
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });

    // console.log(data);
    this.http.post(this.global.apiUrl+'/getSupportAgent',{}, {headers: header}).subscribe(
      (res : any) => {
          // console.log(res);
          this.dataSource = res.Agents;
          this.loading = false;
        },(err => {
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  deleteSupportAgent(id){
    console.log(id)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      id : id,
    }
    this.http.post(this.global.apiUrl+'/deleteSupportAgent', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.dataSource = this.dataSource.filter(element => element.id !== id);
        this.snackBar.open('Support Agent deleted successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        // console.log(this.allCards)
        },(err => {
          // console.log(err)
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          // this.router.navigateByUrl('/');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  EditSupportAgent(data){
    console.log(data);
    this.global.setExtras(data);
    this.router.navigateByUrl('/admin/admin-support-agent-edit');
  }
}

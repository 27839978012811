import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  UsersUsage : any;
  CardUsage : any;
  Packages : any = [];
  loading= false;
  adminForm = this.fb.group({
    admin_text: [''],
  });

  constructor( private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient,
    private global: GlobalService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getUsage();
  }

  getUsage(){
    this.loading= true;
    
    // console.log('get cards')
    // console.log('Bearer '+ localStorage.getItem('admin_access_token'))
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });
    
    // let data = {
      //   user_id : localStorage.getItem('user_id')
      // }
      
      this.http.post(this.global.apiUrl+'/Usage', {}, {headers: header}).subscribe(
        (res : any) => {
        this.loading= false;
        // console.log(res);
        this.UsersUsage = res.UsersUsage;
        this.CardUsage = res.CardUsage;
        this.Packages = res.Packages;
        // console.log(this.allCards)
      },(err => {
          this.loading= false;
          // console.log(err)
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          // this.router.navigateByUrl('/');
          // this.snackBar.open('Sorry! something went wrong.', 'X', {
          //   duration: 3000,
          //   verticalPosition: 'top',
          //   horizontalPosition:'right',
          //   panelClass: ['error_snackBar']
          // });
      })
    )
  }

  AdminData(){
    this.loading= true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    console.log('object data =>', this.adminForm.value);
    this.http.post(this.global.apiUrl+'/admin_text', this.adminForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
          console.log(res);
          this.loading= false;
          this.adminForm.reset();
          this.snackBar.open('Successfully Added.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          this.loading= false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-support-header',
  templateUrl: './support-header.component.html',
  styleUrls: ['./support-header.component.scss']
})
export class SupportHeaderComponent implements OnInit {

  loading = false;
  constructor( private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService) { }

  ngOnInit() {
  }

  logout(){

    // this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });
    this.http.post(this.global.apiUrl+'/auth/logout', {}, {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        // this.loading = false;
        // if(res.status == "Success"){
          localStorage.removeItem('admin_access_token');
          localStorage.removeItem('user_type');
          this.router.navigateByUrl('/admin');
          this.snackBar.open('Support Agent logout successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        // }
       
        },(err => {
          // console.log('err')
          // console.log(err)
          // this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    ) 


    // localStorage.removeItem('admin_access_token');
    // localStorage.removeItem('user_type');
    // this.router.navigateByUrl('/admin');
    // this.snackBar.open('Support Agent logout successfully.', 'X', {
    //   duration: 3000,
    //   verticalPosition: 'top',
    //   horizontalPosition:'right',
    // });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-visitor-chat',
  templateUrl: './visitor-chat.component.html',
  styleUrls: ['./visitor-chat.component.scss']
})
export class VisitorChatComponent implements OnInit {
  user: any;
  loading = false;
  contacts: any = [];
  UserMessageArr: any = [];
  oldUserMessageArr: any = [];
  singleMessageArr: any = [];
  selectedUser: any = null;
  chat;
  search;
  chat_id: any;
  name: any;
  email: any;
  respond_by_name: any;
  status: any;
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.status = localStorage.getItem('status');
    console.log(this.status);

    // this.chat_id  = localStorage.getItem('chat_id');
    // console.log(this.chat_id);
    this.name = localStorage.getItem('visitor_name');
    this.email = localStorage.getItem('visitor_email');
    if (this.status == 'true') { this.getAllUserMsg(); }

  }

  sendUserMsg() {
    // console.log('send')
    this.loading = true;
    console.log(localStorage.getItem('chat_visitor_id'));
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl + '/saveLiveChat', { chat_id: localStorage.getItem('chat_visitor_id'), chat: this.chat, message_type: 'sent' }, { headers: header }).subscribe(
      (res: any) => {
        // console.log('err')
        // console.log(res);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.singleMessageArr = res.messages;
        this.respond_by_name = res.respond_by_name;
        // console.log(this.singleMessageArr)
        // console.log(this.selectedUser);
        this.chat = '';
      }, (err => {
        // console.log('err')
        // console.log(err)
        // this.loading = false;
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  getAllUserMsg() {
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    if (this.router.url === '/visitor-chat') {
      this.http.post(this.global.apiUrl + '/getLiveChat', { chat_id: localStorage.getItem('chat_visitor_id') }, { headers: header }).subscribe(
        (res: any) => {
          // console.log(res)
          this.loading = false;
          this.singleMessageArr = res.messages;
          this.respond_by_name = res.respond_by_name;
        }, (err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error_snackBar']
          });
        })
      )
    }
    setInterval(() => {
      if (this.router.url === '/visitor-chat') {
        this.http.post(this.global.apiUrl + '/getLiveChat', { chat_id: localStorage.getItem('chat_visitor_id') }, { headers: header }).subscribe(
          (res: any) => {
            // console.log(res)
            this.loading = false;
            this.singleMessageArr = res.messages;
            this.respond_by_name = res.respond_by_name;
          }, (err => {
            // console.log('err')
            // console.log(err)
            this.loading = false;
            this.snackBar.open('Sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['error_snackBar']
            });
          })
        )
      }
    }, 30000);

  }

  sendChatEmail() {
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl + '/emailChats', { chat_id: localStorage.getItem('chat_visitor_id') }, { headers: header }).subscribe(
      (res: any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.snackBar.open('Chat send your email  successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
        });
      }, (err => {
        // console.log('err')
        // console.log(err)
        this.loading = false;
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  ChatClose() {
    this.loading = true;
    // console.log(this.chat)
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl + '/closeChat', { chat_id: localStorage.getItem('chat_visitor_id') }, { headers: header }).subscribe(
      (res: any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        if (res.status == "Success") {
          this.router.navigateByUrl('/');
          this.snackBar.open('Close your chat  successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
          });
        }

      }, (err => {
        // console.log('err')
        // console.log(err)
        this.loading = false;
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

}

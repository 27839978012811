import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-admin-chat-view',
  templateUrl: './admin-chat-view.component.html',
  styleUrls: ['./admin-chat-view.component.scss']
})
export class AdminChatViewComponent implements OnInit {

  user: any;
  loading = false;
  contacts : any = [];
  UserMessageArr: any = [];
  oldUserMessageArr: any = [];
  singleMessageArr: any = [];
  selectedUser: any = null;
  chat;
  search;
  name:any;
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
   this.name = localStorage.getItem('chat-view-name');
    this.getAllUserMsg();
  }
  // ngAfterViewInit(){
  //   this.getChatId();
  // }

  getAllUserMsg(){
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/getLiveChat', {chat_id: localStorage.getItem('chat_View_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        this.loading = false;
        // console.log(res);
        this.singleMessageArr = res.messages;
        console.log(this.singleMessageArr)
        // console.log(this.selectedUser);
        this.chat = '';
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

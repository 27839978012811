import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.scss']
})
export class ProfileChangePasswordComponent implements OnInit {

  user: any;
  loading = false;
  confirmPasswordCheck;
  submitted = false;

  changePasswordForm = this.fb.group({
    oldPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength]],
    confirmPassword: ['', Validators.required],
  });

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserData();
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.user = res.Users;
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  get changePasswordFormControl(){
    return this.changePasswordForm.controls;
  }

  checkConfirmPassword(){
    if(this.changePasswordForm.value.newPassword != this.changePasswordForm.value.confirmPassword){
      this.confirmPasswordCheck = true;
    }else{
      this.confirmPasswordCheck = false;
    }
  }

  updatePassword(){
    this.submitted = true;
    let data = {
      email: this.user.email,
      old_password: this.changePasswordForm.controls.oldPassword.value,
      new_password: this.changePasswordForm.controls.newPassword.value,
    };
    // console.log(data);
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    if(this.changePasswordForm.valid){
      // console.log('res');
      this.http.post(this.global.apiUrl+'/changePassword', data, {headers: header}).subscribe(
        (res : any) => {
          // console.log(res);
            // localStorage.setItem('access_token', res.access_tokken);
            // localStorage.setItem('user_id', res.user.id);
            // this.router.navigateByUrl('/cards');
            // console.log(data);
            if(res.status == "Failed"){
              this.snackBar.open('Please enter your correct old password.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
            }else{
                this.snackBar.open('Password Updated successfully.', 'X', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition:'right',
                });
                this.changePasswordForm.reset();
                this.router.navigateByUrl('/profile')
              }
            },(err => {
              // console.log(err)
            this.router.navigateByUrl('/profile')
            this.snackBar.open('Sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
    }else if(this.changePasswordForm.value.email != this.changePasswordForm.value.confirmEmail){
      this.confirmPasswordCheck = true;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})

export class AdminProfileComponent implements OnInit {

 
  id;
  loading;
  admin: any;

  adminProfileForm = this.fb.group({
    email: [''],
    old_password: [''],
    new_password: [''],
  });

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getAdminData()
  }

  submitPackageData(){
    // console.log(this.adminProfileForm.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
  
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });
  
   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/createPackage', this.adminProfileForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/admin/packages');
          this.snackBar.open('Package added successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
  
  updateProfile(){
    // console.log(this.adminProfileForm.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
    this.adminProfileForm.patchValue({id : this.id})
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });
  
   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/updatePasswordAdmin', this.adminProfileForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        if(res.message == 'Your current password does not matches with the password you provided. Please try again.'){
          this.snackBar.open(res.message, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
        }else{
          this.snackBar.open('Password updated successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        }
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getAdminData(){
    // console.log(this.adminProfileForm.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
    this.adminProfileForm.patchValue({id : this.id})
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });
  
   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/getAdmin', {} ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.admin= res.admin;
        this.adminProfileForm.patchValue({email: this.admin.email})
        // this.adminProfileForm.patchValue(res.Package)
        // this.router.navigateByUrl('/admin/packages');
        //   this.snackBar.open('Package updated successfully.', 'X', {
        //     duration: 3000,
        //     verticalPosition: 'top',
        //     horizontalPosition:'right',
        //   });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

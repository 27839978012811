import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-admin-package-control-form',
  templateUrl: './admin-package-control-form.component.html',
  styleUrls: ['./admin-package-control-form.component.scss']
})
export class AdminPackageControlFormComponent implements OnInit {

  id;
  loading;

  packageForm = this.fb.group({
    id: [''],
    price: [''],
    net_price: [''],
    description: [''],
    image:[''],
    limit: [''],
    expire_in: [''],
    logo: [''],
    product_image: [''],
    social_media_listing: [''],
    website: [''],
    videos: ['']
  });

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id);
    if(this.id != null){
      this.getPackageData(this.id)
    }
  }

  submitPackageData(){
    // console.log(this.packageForm.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
  
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
  
   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/createPackage', this.packageForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/admin/packages');
          this.snackBar.open('Package added successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
  
  editPackageData(){
    // console.log(this.packageForm.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
    this.packageForm.patchValue({id : this.id})
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
  
   
    // this.basicInfoForm.value
    
    console.log('package data =>', this.packageForm.value);
    this.http.post(this.global.apiUrl+'/updatePackage', this.packageForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/admin/packages');
          this.snackBar.open('Package updated successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getPackageData(id){
    // console.log(this.packageForm.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;
    this.packageForm.patchValue({id : this.id})
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
  
   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/getPackageSingle', {id: id} ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.packageForm.patchValue(res.Package)
        // this.router.navigateByUrl('/admin/packages');
        //   this.snackBar.open('Package updated successfully.', 'X', {
        //     duration: 3000,
        //     verticalPosition: 'top',
        //     horizontalPosition:'right',
        //   });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
}

import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
  addContactForm: FormGroup;
  
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddContactComponent>,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }
    
  ngOnInit() {
    this.addContactForm = this.fb.group({
      firstname: '',
      lastname: '',
      tags: '',
      phone: this.fb.array([
        this.fb.group({
          phone: '',
        })
      ]),
      email: this.fb.array([
        this.fb.group({
          email: '',
        })
      ]),
      address: this.fb.array([
        this.fb.group({
          address: '',
        })
      ]),
      gender: '',
      company: '',
      position: '',
      website: this.fb.array([
        this.fb.group({
          website: '',
        })
      ]),
      facebook: '',
      twitter: '',
      linkedin: ''
    });

    if(this.data){
      // console.log(this.data.id)
      this.getContact(this.data.id)
    }
  }

  get phoneArr() { return <FormArray>this.addContactForm.get('phone'); }
  get emailArr() { return <FormArray>this.addContactForm.get('email'); }
  get addressArr() { return <FormArray>this.addContactForm.get('address'); }
  get websiteArr() { return <FormArray>this.addContactForm.get('website'); }

  addPhone(){
    let control = <FormArray>this.addContactForm.controls.phone;
    control.push(
      this.fb.group({
        phone: "",
      })
    );
  }

  addEmail(){
    let control = <FormArray>this.addContactForm.controls.email;
    control.push(
      this.fb.group({
        email: "",
      })
    );
  }

  addAddress(){
    let control = <FormArray>this.addContactForm.controls.address;
    control.push(
      this.fb.group({
        address: "",
      })
    );
  }

  addWebsite(){
    let control = <FormArray>this.addContactForm.controls.website;
    control.push(
      this.fb.group({
        website: "",
      })
    );
  }

  removeItem(control, i){
    control.removeAt(i);
  }

  addContact(){
    // console.log(this.addContactForm.get('phone'));
    // console.log(this.addContactForm.value);
    
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data : any = {
      user_id: localStorage.getItem('user_id'),
      firstname: this.addContactForm.get('firstname').value,
      lastname: this.addContactForm.get('lastname').value,
      tags: this.addContactForm.get('tags').value,
      phone:  JSON.stringify(this.addContactForm.get('phone').value),
      email: JSON.stringify(this.addContactForm.get('email').value),
      address:  JSON.stringify(this.addContactForm.get('address').value),
      gender: this.addContactForm.get('gender').value,
      company: this.addContactForm.get('company').value,
      position: this.addContactForm.get('position').value,
      website: JSON.stringify(this.addContactForm.get('website').value),
      facebook: this.addContactForm.get('facebook').value,
      twitter: this.addContactForm.get('twitter').value,
      linkedin: this.addContactForm.get('linkedin').value
    }
    // console.log(data)
    if(this.data){
      data.id = this.data.id;
      // console.log(data)
      this.http.post(this.global.apiUrl+'/updateCustomContact', data , {headers: header}).subscribe(
        (res : any) => {
          // console.log(res);
          // this.router.navigateByUrl('/cards');
           this.dialogRef.close(res.Contact);
            this.snackBar.open('Contact updated successfully.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
            });
          },(err => {
            // console.log(err)
            // this.router.navigateByUrl('/cards');
            // this.snackBar.open('Sorry! something went wrong.', 'X', {
            //   duration: 3000,
            //   verticalPosition: 'top',
            //   horizontalPosition:'right',
            //   panelClass: ['error_snackBar']
            // });
        })
      )
    }else{
      this.http.post(this.global.apiUrl+'/saveCustomContact', data , {headers: header}).subscribe(
        (res : any) => {
          // console.log(res);
          // this.router.navigateByUrl('/cards');
           this.dialogRef.close(res.Contact);
            this.snackBar.open('Contact created successfully.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
            });
          },(err => {
            // console.log(err)
            // this.router.navigateByUrl('/cards');
            // this.snackBar.open('Sorry! something went wrong.', 'X', {
            //   duration: 3000,
            //   verticalPosition: 'top',
            //   horizontalPosition:'right',
            //   panelClass: ['error_snackBar']
            // });
        })
      )
    }
  }

  getContact(id){
    // console.log('user')
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl+'/getCustomContactSingle', {id:id}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.addContactForm.patchValue(res.customContact);
      },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

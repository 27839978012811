import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { ShareCardLinkModalComponent } from '../share-card-link-modal/share-card-link-modal.component';
import { MatDialog } from '@angular/material';
import { VCard } from 'ngx-vcard';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-all-templates',
  templateUrl: './all-templates.component.html',
  styleUrls: ['./all-templates.component.scss']
})
export class AllTemplatesComponent implements OnInit {

  @Input() id : any;
  @Input() tempInfo : any;
  card_id;
  user_id:any;

  config  : SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    slideToClickedSlide: true,
    loop: true,
    // simulateTouch: true,
    // spaceBetween: 15,
     navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  public vCard: VCard = {
    name: {
      firstNames: '',
      lastNames: ''
    },
    telephone:[],
    email:[],
    organization:'',
   };
   qrdata: any;
  constructor(public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router, 
    private http: HttpClient,
    private global: GlobalService) { 
    }
  
  ngOnInit() {
    console.log('tempInfo =>', this.tempInfo);
    this.card_id = this.route.snapshot.paramMap.get('id');
    console.log(this.card_id);
    this.qrdata = 'https://card.proliftbrand.com/cards/share-card/'+this.card_id;
    // this.tempInfo.changeProductImages = JSON.parse(this.tempInfo.changeProductImages)
    // console.log(this.tempInfo)
    if(this.card_id != null){
      console.log('crad id '+ this.card_id);
      this.getCard(this.card_id);
    }
  }

  openShareLinkModal(){
    let url = 'https://card.proliftbrand.com/cards/share-card/'+this.card_id;
    console.log(url);
    
    this.dialog.open(ShareCardLinkModalComponent, {
      width: '400px',
      data: {url : url}
    });
  }

  followMyCard()
  {
    // alert(this.user_id);

    this.router.navigate(['**',{id:this.user_id}]);

  }

  saveCard()
  {
    alert('card save');
  }
  getCard(id){
    // this.loading = true;
    // console.log('get')
    // console.log(id)
    // let header : HttpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    // });
    
    let data = {
      // user_id: localStorage.getItem('user_id'),
      card_id: id
    };
    this.http.post(this.global.apiUrl+'/getSingleCard', data).subscribe(
      (res : any) => {
        // this.loading = false;
        console.log(res)
        this.user_id = res.Card.user_id;
        // console.log(res.Card.infoFormData.firstName);
        // console.log(res.Card.infoFormData.lastName);
        // console.log(res.Card.infoFormData.phoneNumber);
        this.vCard.name.firstNames = res.Card.infoFormData.firstName;
        this.vCard.name.lastNames = res.Card.infoFormData.lastName;
        this.vCard.telephone.push(res.Card.infoFormData.phoneNumber);
        this.vCard.email.push(res.Card.infoFormData.email);
        this.vCard.organization = res.Card.infoFormData.companyName;

        console.log(this.vCard);
        // this.infoFormGroup.patchValue(res.Card.infoFormData)
        // this.socialFormGroup.patchValue(res.Card.socialFormData)
        // this.changePhotos = res.Card.change_photo;
        // this.templateInfo.infoFormData = res.Card.infoFormData;
        // this.templateInfo.socialFormData = res.Card.socialFormData;
        // this.templateInfo.change_photo = res.Card.change_photo;
        // this.templateInfo.change_logo = res.Card.change_logo;
        // this.templateInfo.changeProductImages = JSON.parse(res.Card.changeProductImages);
        // this.templateId = res.Card.infoFormData.templateId;
        // this.templateInfo.buttonColor = res.Card.colorTheme;
        // console.log('res.Card.inforFormData.templateId');
        // console.log(res.Card.infoFormData);
        // console.log(res.Card.infoFormData.templateId);
      },(err => {
        // this.loading = false;
        console.log(err)
        // this.router.navigateByUrl('/cards');
        //   this.snackBar.open('Sorry! something went wrong.', 'X', {
        //     duration: 3000,
        //     verticalPosition: 'top',
        //     horizontalPosition:'right',
        //     panelClass: ['error_snackBar']
        //   });
      })
    )
  }

}

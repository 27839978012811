import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-personal-contact',
  templateUrl: './personal-contact.component.html',
  styleUrls: ['./personal-contact.component.scss']
})
export class PersonalContactComponent implements OnInit {

  friend: any;
  loading = false;

  constructor(private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService) {
  }

  ngOnInit() {
    // console.log('on')
    this.route.params.subscribe(params => {
      // each time the search data is change you'll get this running
      //Do what ever you need to refresh your search page
      // console.log(params);
      // console.log('New route params');
      this.getUserData(params.id);
    });
  }

  getUserData(id){
    // console.log('user')
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl+'/getCustomContactSingle', {id:id}, {headers: header}).subscribe(
      (res : any) => {
        this.loading = false;
        // console.log(res);
        this.friend = res.customContact;
      },(err => {
          this.loading = false;
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-users-control',
  templateUrl: './admin-users-control.component.html',
  styleUrls: ['./admin-users-control.component.scss']
})
export class AdminUsersControlComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email','package','balance_trans_id','balance_trans_type','payment_id', 'payment_method', 'total_referals', 'limit', 'action'];
  dataSource : any = [];
  loading = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers(){
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });
    this.http.post(this.global.apiUrl+'/getAllUsers',{}, {headers: header}).subscribe(
      (res : any) => {
          console.log('all user =>', res);
          this.dataSource = res.Users;
          this.loading = false;
        },(err => {
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  deleteUser(id){
    // console.log(id)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      user_id : id,
    }
    this.http.post(this.global.apiUrl+'/deleteUser', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.dataSource = this.dataSource.filter(element => element.id !== id);
        this.snackBar.open('User deleted successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        // console.log(this.allCards)
        },(err => {
          // console.log(err)
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          // this.router.navigateByUrl('/');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
  
}

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-visitor-login',
  templateUrl: './visitor-login.component.html',
  styleUrls: ['./visitor-login.component.scss']
})
export class VisitorLoginComponent implements OnInit {

  submitted = false;
  
  vistorLoginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', [Validators.required]],
  });

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  get loginControls(){
    return this.vistorLoginForm.controls;
  }

  submitLogin(){
    this.submitted = true;
    let data = this.vistorLoginForm.value;
    console.log(data);
    // this.router.navigateByUrl('visitor-chat');
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    if(this.vistorLoginForm.valid){
      this.http.post(this.global.apiUrl+'/startLiveChat', data, {headers: header}).subscribe(
        (res : any) => {
          localStorage.setItem('chat_visitor_id', res.LiveChat.id);
          localStorage.setItem('visitor_name', data.name);
          localStorage.setItem('visitor_email', data.email);
          localStorage.setItem('status', res.online);
          console.log(res);
          this.router.navigateByUrl('visitor-chat');
          // if(res.message != "Unauthorized"){
          //   localStorage.setItem('admin_access_token', res.access_token);
          //   // localStorage.setItem('admin_user_id', res.user.id);
          //   this.router.navigateByUrl('/admin/dashboard');
          //   this.snackBar.open('Admin login successfully.', 'X', {
          //     duration: 3000,
          //     verticalPosition: 'top',
          //     horizontalPosition:'right',
          //   });
          // }else{
          //     this.snackBar.open('Enter correct credentials.', 'X', {
          //       duration: 3000,
          //       verticalPosition: 'top',
          //       horizontalPosition:'right',
          //       panelClass: ['error_snackBar']
          //     });
          //   }
          },(err => {
            // console.log(err)
            this.snackBar.open('Enter correct credentials.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  query;
  userList:any = [];
  list = false;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  logout(){
    localStorage.removeItem('user_id');
    localStorage.removeItem('access_token');
    this.router.navigateByUrl('/');
    this.snackBar.open('Logout successfully.', 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition:'right',
    });
  }

  ngOnInit(){

  }

  hideList(){
    // console.log('hide')
    setTimeout(() => {
      this.list = false;
    }, 500);
  }
  
  showList(){
    // console.log('show')
    this.list = true;
    // console.log(this.userList)
    // console.log(this.userList.length)
  }

  searchUsers(){

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/searchUser', {query: this.query, user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.userList = res.Users;
        // this.router.navigateByUrl('/cards');
        //   this.snackBar.open('Card created successfully.', 'X', {
        //     duration: 3000,
        //     verticalPosition: 'top',
        //     horizontalPosition:'right',
        //   });
      
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

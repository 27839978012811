import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-basic-information',
  templateUrl: './profile-basic-information.component.html',
  styleUrls: ['./profile-basic-information.component.scss']
})
export class ProfileBasicInformationComponent implements OnInit {

  user: any;
  loading = false;
  contacts : any = [];

  basicInfoForm = this.fb.group({
    user_id: localStorage.getItem('user_id'),
    firstname: [''],
    lastname: [''],
    email: [''],
    DOB: [''],
    gender: [''],
    city: [''],
    country: [''],
    business_type:[''],
    sub_category:[''],
    profile_image: ['']
  });

  BusinessType : any = [
  ];
  SubBusinessType : any = [];

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserData();
    
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        this.user = res.Users;
        this.basicInfoForm.patchValue(this.user);
        // this.getCategories();
       

        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  editBasicInfo(){
    console.log(this.basicInfoForm.value)
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/editUser', this.basicInfoForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        // this.router.navigateByUrl('/cards');
          this.snackBar.open('Profile updated successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
          this.getUserData();
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getBase64Image(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //me.modelvalue = reader.result;
      this.basicInfoForm.patchValue({profile_image : reader.result})
      // console.log(reader.result);
      // console.log(this.basicInfoForm.value);
    };
    reader.onerror = (error) => {
      // console.log('Error: ', error);
    };
  }

  getCategories(){

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accept': 'application/json' 
      });
    this.http.post(this.global.apiUrl+'/all-categories', {}, {headers: header}).subscribe(
          (res : any) => {
            console.log(res);
            if(res.status == "Success"){
            console.log('main cat =>', res.Categories);
            this.BusinessType = res.Categories;
            this.selectBuinessType(this.user.business_type);
            }else{
              this.snackBar.open('No Found.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
            }
            }
            ,(err => {
              // console.log(err)
              this.snackBar.open('Sorry! something went wrong.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
          })
        )
    
    }
  
    selectBuinessType(event)
    {
      console.log('change');
      console.log(event);
  
      let data : any = {
      business_type: event 
      };

      console.log('sub cat', data);
  
      let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accept': 'application/json' 
      });
     this.http.post(this.global.apiUrl+'/sub-categories', data, {headers: header}).subscribe(
          (res : any) => {
            console.log(res);
            if(res.status == "Success"){
          console.log('sub cat =>', res.Categories);
          this.SubBusinessType = res.Categories;
            }else{
              this.snackBar.open('Buiness Type is Required.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
            }
            }
            ,(err => {
              // console.log(err)
              this.snackBar.open('Sorry! something went wrong.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
          })
        )
  
    } 


}

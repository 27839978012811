import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class NonSecureGuardService implements CanActivate {

	constructor(private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (localStorage.getItem('access_token') == null && localStorage.getItem('access_token') == undefined) {
			// console.log('nul1 true')
			return true;
		}
		else {
			
			this.router.navigateByUrl('/cards');
			// console.log('nul1 false')
			return false;
		}
	}

}
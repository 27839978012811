import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-register-learn-more-modal',
  templateUrl: './register-learn-more-modal.component.html',
  styleUrls: ['./register-learn-more-modal.component.scss']
})
export class RegisterLearnMoreModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RegisterLearnMoreModalComponent>
  ) {
    
  }

  ngOnInit() {
  }

  closeModal(){
    this.dialogRef.close();
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bonus-ammount',
  templateUrl: './bonus-ammount.component.html',
  styleUrls: ['./bonus-ammount.component.scss']
})
export class BonusAmmountComponent implements OnInit {

 

  displayedColumns: string[] = ['date', 'email', 'total_package_amount', 'bonus_amount', 'status'];
  dataSource : any = [
    // {
    //   date:'2020-5-2',
    //   email:'cvsjhjh@xhbckj.cvdj',
    //   total_package_amount:'123',
    //   bonus_amount:'98',
    //   status:'pending'
    // }
  ];

  user:any;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserData();
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/listOfReferals', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        // localStorage.setItem('invite_Code',res.Users.refer_code);
        // this.user = res;
        this.dataSource = res.listOfReferals;
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sendRequest(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/applyForReferral', {user_id: localStorage.getItem('user_id')} , {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        this.snackBar.open('Referral request sent successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

import { Component,Inject, OnInit, Optional } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface PackageData {
  image: any;
}

@Component({
  selector: 'app-product-images-cropper',
  templateUrl: './product-images-cropper.component.html',
  styleUrls: ['./product-images-cropper.component.scss']
})
export class ProductImagesCropperComponent implements OnInit {

  imageBase64: string = '';
  objectKeys = Object.keys;
  fileName: string;
  cropReady = false;
  croppedImages: any =[];
  imageName: string;
  croppedImageLimit = 0;
  singleCropImage: any;
  limit: any;


  fileChangeEvents(event: any): void {
     const fileReader = new FileReader();
     fileReader.onload = (event: any) => {
                     this.imageBase64 = event.target.result;
     };
             fileReader.readAsDataURL(event.target.files[0]);
             this.imageName = event.target.files[0].name
             // console.log(event.target.files[0].name);
}
imageCrop(event: ImageCroppedEvent ) {
  this.singleCropImage = event.base64;
  // console.log(this.croppedImages)
  // console.log(this.croppedImages.length)
}
imageLoad() {
 this.cropReady = true;
}
loadImageFail () {
  // console.log('Load failed');
}
addCropImage(){
  // console.log('push')
  this.croppedImages.push(this.singleCropImage);
  this.croppedImageLimit = this.croppedImages.length;
  // console.log(this.croppedImages);
  // setTimeout(() => {
  //   const element = document.querySelector('.preview_images');
  //   if (element) {
  //     element.scrollIntoView({ block: 'center' });
  //   }
  // }, 500);
}
  // imageChangedEvent: any = '';
  // croppedImage: any = '';
  
  constructor(private dialogRef: MatDialogRef<ProductImagesCropperComponent>,
    private snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
    ) { 
      // console.log(data);
      this.limit = (data === '1 image') ? 1 : 3;
    }
  
  ngOnInit() {
  }
  
  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }

  // imageCropped(event: ImageCroppedEvent) {
  //   // console.log(event)
  //   event.width = 800;
  //   event.height = 800;
  //   this.croppedImage = event.base64;
  // }

  // loadImageFailed() {
  //   this.dialogRef.close(null);
  //   this.snackBar.open('Sorry, something went wrong.Please Try Again.', 'X', {
  //     duration: 3000,
  //     verticalPosition: 'top',
  //     horizontalPosition:'right',
  //     panelClass: ['error_snackBar']
  //   });
  // }

  saveImage(){
    if(this.croppedImageLimit != 0){
      // console.log(this.croppedImageLimit)
      this.dialogRef.close(this.croppedImages);
    }else{
      // console.log('this.croppedImageLimit')
      this.dialogRef.close(undefined);
    }
  }

  closeModal(){
    // console.log('close')
    this.dialogRef.close(undefined);
  }


}

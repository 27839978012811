import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {


  user: any;
  loading = false;
  contacts : any = [];
  UserMessageArr: any = [];
  oldUserMessageArr: any = [];
  singleMessageArr: any = [];
  selectedUser: any = null;
  chat;
  search;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserMessages();
    // console.log(this.global.sentMessageUserObj);
  }

  searchFunction(){
    // console.log(this.oldUserMessageArr);
    if(this.oldUserMessageArr != undefined){
      let filterData : any =[];
      this.oldUserMessageArr.filter((el : any) => {
        if(el.firstname.toLowerCase().includes(this.search) || el.lastname.toLowerCase().includes(this.search)){
          filterData.push(el);
        }
      });
  
      this.UserMessageArr = filterData;
      if(this.search == ''){
        this.UserMessageArr = this.oldUserMessageArr;
      }
    }
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.user = res.Users;
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getUserMessages(){
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/connectedUserMsg', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        if(res.ConnectedUser){
          this.UserMessageArr = res.ConnectedUser;
        }
        // console.log(res)
        // console.log(this.UserMessageArr)
        // console.log(this.global.sentMessageUserObj)
        this.global.sentMessageUserObj.forEach(element => {
          this.UserMessageArr = this.UserMessageArr.filter(el => el.id != element.id);
          this.UserMessageArr.push(element);
          // console.log(this.UserMessageArr);
        });
        if(this.UserMessageArr != undefined){

        }
        // this.router.navigateByUrl('/cards');
        //   this.snackBar.open('Card created successfully.', 'X', {
        //     duration: 3000,
        //     verticalPosition: 'top',
        //     horizontalPosition:'right',
        //   });
        },(err => {
          // console.log('err')
          // console.log(err)
          // this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sendUserMsg(){
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/sendMessage', {user_id: localStorage.getItem('user_id'), friend_id: this.selectedUser.id, chat: this.chat}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.singleMessageArr = res.Chat;
        // console.log(this.singleMessageArr)
        // console.log(this.selectedUser);
        this.chat = '';
        },(err => {
          // console.log('err')
          // console.log(err)
          // this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getUserAllMsg(friend){
    this.selectedUser = friend;
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    // console.log(this.router.url);
    setInterval(() => {
      if(this.router.url === '/messages' && this.selectedUser != null){
        this.http.post(this.global.apiUrl+'/getMessages', {user_id: localStorage.getItem('user_id'), friend_id: friend.id}, {headers: header}).subscribe(
          (res : any) => {
            // console.log('err')
            // console.log(res);
            this.loading = false;
            this.singleMessageArr = res.Chat;
            // console.log(this.singleMessageArr)
            // console.log(this.selectedUser)
            // this.UserMessageArr = res.ConnectedUser;
            // this.router.navigateByUrl('/cards');
            //   this.snackBar.open('Card created successfully.', 'X', {
            //     duration: 3000,
            //     verticalPosition: 'top',
            //     horizontalPosition:'right',
            //   });
            },(err => {
              // console.log('err')
              // console.log(err)
              // this.loading = false;
              this.router.navigateByUrl('/cards');
              this.snackBar.open('Sorry! something went wrong.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
          })
        )
        // console.log('message getted')
      }
    }, 5000);
    
  }

  
}

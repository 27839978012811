import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-edit-user',
  templateUrl: './admin-edit-user.component.html',
  styleUrls: ['./admin-edit-user.component.scss']
})
export class AdminEditUserComponent implements OnInit {


  user: any;
  loading = false;
  contacts : any = [];
  id : any = 0;

  basicInfoForm = this.fb.group({
    user_id: this.id,
    firstname: [''],
    lastname: [''],
    email: [''],
    DOB: [''],
    gender: [''],
    city: [''],
    country: [''],
    profile_image: [''],
    company: [''],
    job_title: [''],
    website: [''],
    facebook_url: [''],
    twitter_url: [''],
    linkedin_url: [''],
    youtube_url: [''],
  });

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getUserData();
  }

  getUserData(){

    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: this.id}, {headers: header}).subscribe(
      (res : any) => {
        this.loading = false;
        // console.log(res);
        this.user = res.Users;
        this.basicInfoForm.patchValue(this.user);
      },(err => {
        // console.log(err)
        this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  editBasicInfo(){
    // console.log(this.basicInfoForm.value)
    this.loading = true;
    this.basicInfoForm.patchValue({user_id: this.id})

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

   
    // this.basicInfoForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/editUser', this.basicInfoForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        // this.router.navigateByUrl('/cards');
          this.snackBar.open('Profile updated successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
          this.getUserData();
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getBase64Image(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //me.modelvalue = reader.result;
      this.basicInfoForm.patchValue({profile_image : reader.result})
      // console.log(reader.result);
      // console.log(this.basicInfoForm.value);
    };
    reader.onerror = (error) => {
      // console.log('Error: ', error);
    };
  }

}

import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  
  constructor(private dialogRef: MatDialogRef<ImageCropperModalComponent>,
    private snackBar: MatSnackBar) { }
  
  ngOnInit() {
  }
  
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    // console.log(event)
    event.width = 800;
    event.height = 800;
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.dialogRef.close(null);
    this.snackBar.open('Sorry, something went wrong.Please Try Again.', 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition:'right',
      panelClass: ['error_snackBar']
    });
  }

  saveImage(){
    this.dialogRef.close(this.croppedImage);
  }

}

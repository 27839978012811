import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit {

  user: any;
  loading = false;
  contacts : any = [];
  UserMessageArr: any = [];
  oldUserMessageArr: any = [];
  singleMessageArr: any = [];
  selectedUser: any = null;
  chat;
  search;
  name:any;
  email:any;
  respond_name:any;
  status:any;
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.name =  localStorage.getItem('firstname');
    this.email = localStorage.getItem('user_email');
    this.getChatId();
  }
  // ngAfterViewInit(){
  //   this.getChatId();
  // }

  getChatId(){
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    let data = {
      email: localStorage.getItem('user_email'), 
      name: localStorage.getItem('firstname')
    }
    console.log(data);
    // console.log(localStorage.getItem('firstname'));
    // console.log(localStorage.getItem('user_email'));
    // {name: localStorage.getItem('firstname'),  email:localStorage.getItem('user_email')}
    this.http.post(this.global.apiUrl+'/startLiveChat', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        localStorage.setItem('chat_id', res.LiveChat.id);

        this.status = res.online;
        // this.getAllUserMsg(res.LiveChat.id);
        this.getAllUserMsg();
        // console.log(this.selectedUser);
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sendUserMsg(){
    // console.log('send')
    this.loading = true;
    console.log(localStorage.getItem('chat_id'));
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/saveLiveChat', {chat_id: localStorage.getItem('chat_id'),  chat: this.chat,message_type: 'sent'}, {headers: header}).subscribe(
      (res : any) => {
        
        // console.log('err')
        // console.log(res);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.singleMessageArr = res.messages;
        this.respond_name  = res.respond_by_name;
        // console.log(this.singleMessageArr)
        // console.log(this.selectedUser);
        this.chat = '';
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getAllUserMsg(){
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    if(this.router.url === '/live-chat'){
      this.http.post(this.global.apiUrl+'/getLiveChat', {chat_id: localStorage.getItem('chat_id')}, {headers: header}).subscribe(
        (res : any) => {
          // console.log('err')
          // console.log(res);
          this.loading = false;
          this.singleMessageArr = res.messages;
          this.respond_name  = res.respond_by_name;
          },(err => {
            // console.log('err')
            // console.log(err)
            this.loading = false;
            this.snackBar.open('Sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
    }
    
    setInterval(() => {
      if(this.router.url === '/live-chat'){
      this.http.post(this.global.apiUrl+'/getLiveChat', {chat_id: localStorage.getItem('chat_id')}, {headers: header}).subscribe(
        (res : any) => {
          // console.log('err')
          // console.log(res);
          this.loading = false;
          this.singleMessageArr = res.messages;
          this.respond_name  = res.respond_by_name;
          },(err => {
            // console.log('err')
            // console.log(err)
            this.loading = false;
            this.snackBar.open('Sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
    }
    }, 30000);
    
  }

  sendChatEmail(){
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/emailChats', {chat_id: localStorage.getItem('chat_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.loading = false;
        this.snackBar.open('Chat send your email  successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right',
        });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  ChatClose(){
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/closeChat', {chat_id: localStorage.getItem('chat_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        if(res.status == "Success"){
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Close your chat  successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        }
       
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    ) 
  }

}

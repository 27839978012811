import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  activities: any = [];
  oldActivities: any = [];
  loading = false;
  search;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getActivities();
  }

  getActivities(){

    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      user_id: localStorage.getItem('user_id'),
    };
    this.http.post(this.global.apiUrl+'/getActivity', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res)
        this.activities = res.Activity;
        this.oldActivities = res.Activity;
        this.loading = false;
      },(err => {
          this.loading = false;
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
  
  deleteActivity(id){
    this.loading = true;

    // console.log(id)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
  
    let data = {
      user_id: localStorage.getItem('user_id'),
      activity_id: id
    };
    this.http.post(this.global.apiUrl+'/deleteActivity', data, {headers: header}).subscribe(
      (res : any) => {
          // console.log(res)
          this.activities = this.activities.filter(element => element.id != id);
          this.loading = false;
          this.snackBar.open('Activity deleted successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          // console.log(err);
          this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  searchFunction(){
    // console.log(this.search);

    let filterData : any =[];
    this.oldActivities.filter(el => {
      if(el.activity.toLowerCase().includes(this.search)){
        filterData.push(el);
      }
    });

    this.activities = filterData;
    if(this.search == ''){
      this.activities = this.oldActivities;
    }
  }

}

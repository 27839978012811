import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-work-social',
  templateUrl: './profile-work-social.component.html',
  styleUrls: ['./profile-work-social.component.scss']
})
export class ProfileWorkSocialComponent implements OnInit {

  user: any;
  loading = false;
  contacts : any = [];
  socialForm = this.fb.group({
    user_id: localStorage.getItem('user_id'),
    company: [''],
    job_title: [''],
    website: [''],
    facebook_url: [''],
    twitter_url: [''],
    linkedin_url: [''],
    youtube_url: [''],
  });

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserData();
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.user = res.Users;
        this.socialForm.patchValue(this.user);
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  editSocialInfo(){
    // console.log(this.socialForm.value)
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

   
    // this.socialForm.value
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/editUser', this.socialForm.value ,{headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        // this.router.navigateByUrl('/cards');
          this.snackBar.open('Profile updated successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
          this.getUserData();
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
}

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  submitted = false;
  
  passwordResetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  get loginControls(){
    return this.passwordResetForm.controls;
  }

  passwordReset(){
    this.submitted = true;
    let data = this.passwordResetForm.value;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // console.log(data);
    if(this.passwordResetForm.valid){
      this.http.post(this.global.apiUrl+'/resetPassword', data, {headers: header}).subscribe(
        (res : any) => {
          // console.log(res);
          if(res.message != "Email is not registered."){
            // localStorage.setItem('admin_user_id', res.user.id);
            this.router.navigateByUrl('/')
            this.snackBar.open('Email sent to your email sucessfully.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
            });
          }else{
              this.snackBar.open('Email is not registered.', 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
            }
          },(err => {
            // console.log(err)
            this.snackBar.open('something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
    }
  }


}

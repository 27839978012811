import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-amount',
  templateUrl: './admin-amount.component.html',
  styleUrls: ['./admin-amount.component.scss']
})
export class AdminAmountComponent implements OnInit {

  displayedColumns: string[] = ['date', 'referal_email', 'referal_code', 'user_email', 'amount_of_package',
   'bonus_amount','status','action'];
  dataSource : any = [];
  loading = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers(){
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });

    // console.log(data);
    this.http.post(this.global.apiUrl+'/listOfReferals',{}, {headers: header}).subscribe(
      (res : any) => {
          console.log(res);
          this.dataSource = res.listOfReferals;
          this.loading = false;
        },(err => {
          console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  changeStatus(id){
    console.log(id)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      id : id,
    }
    console.log(data); 
    this.http.post(this.global.apiUrl+'/changeStatus', data, {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        // this.dataSource = this.dataSource.filter(element => element.id !== id);
        this.snackBar.open('Status updated to Paid.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        // console.log(this.allCards)
        },(err => {
          // console.log(err)
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          // this.router.navigateByUrl('/');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
    // console.log(localStorage.getItem('access_token'))
    if(localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != undefined){
      // console.log('nul true')
      return true;
    }else{
      // console.log('nul false')
      
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
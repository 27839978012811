import { Component,Inject, OnInit, Optional } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface PackageData {
  image: any;
}

@Component({
  selector: 'app-product-images-cropper',
  templateUrl: './product-images-cropper.component.html',
  styleUrls: ['./product-images-cropper.component.scss']
})
export class ProductImagesCropperComponent implements OnInit {

  imageBase64: string = '';
  objectKeys = Object.keys;
  fileName: string;
  cropReady = false;
  croppedImages: any =[];
  imageName: string;
  croppedImageLimit = 0;
  singleCropImage: any;
  croppedImageFile: File | null = null; 
  croppedImagesFile: File[] = []; 
  limit: any;


  fileChangeEvents(event: any): void {
     const fileReader = new FileReader();
     fileReader.onload = (event: any) => {
                     this.imageBase64 = event.target.result;
     };
             fileReader.readAsDataURL(event.target.files[0]);
             this.imageName = event.target.files[0].name
             // console.log(event.target.files[0].name);
}
imageCrop(event: ImageCroppedEvent ) {
  this.singleCropImage = event.base64;
  this.croppedImageFile = this.base64ToFile(event.base64, 'cropped-image.png');
}
imageLoad() {
 this.cropReady = true;
}
loadImageFail () {
  // console.log('Load failed');
}
addCropImage(){
  // console.log('push')
  this.croppedImages.push(this.singleCropImage);
  this.croppedImageLimit = this.croppedImages.length;
  this.croppedImagesFile.push(this.croppedImageFile);
}
  
  constructor(private dialogRef: MatDialogRef<ProductImagesCropperComponent>,
    private snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
    ) { 
      // console.log(data);
      this.limit = (data === '1 image') ? 1 : 3;
    }
  
  ngOnInit() {
  }

  saveImage(){
    if(this.croppedImageLimit != 0){
      // console.log(this.croppedImageLimit)
      const data = {
        croppedImages: this.croppedImages,
        croppedImagesFile: this.croppedImagesFile
      }
      this.dialogRef.close(data);
    }else{
      // console.log('this.croppedImageLimit')
      this.dialogRef.close(undefined);
    }
  }

  closeModal(){
    // console.log('close')
    this.dialogRef.close(undefined);
  }

    // Helper function to convert base64 to File
    base64ToFile(base64: string, filename: string): File {
      const byteString = atob(base64.split(',')[1]); // Decode base64 string
      const arrayBuffer = new ArrayBuffer(byteString.length); // Create an ArrayBuffer
      const uint8Array = new Uint8Array(arrayBuffer);
  
      // Convert byteString to Uint8Array
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
  
      // Create a Blob with the appropriate mime type
      const blob = new Blob([uint8Array], { type: 'image/png' });
  
      // Convert Blob to File
      return new File([blob], filename, { type: 'image/png' });
    }


}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-support-agent-home',
  templateUrl: './support-agent-home.component.html',
  styleUrls: ['./support-agent-home.component.scss']
})
export class SupportAgentHomeComponent implements OnInit {

  user: any;
  loading = false;
  contacts: any = [];
  UserMessageArr: any = [];
  oldUserMessageArr: any = [];
  singleMessageArr: any = [];
  selectedUser: any = null;
  chat;
  search;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserMessages();
    // console.log(this.global.sentMessageUserObj);
  }

  searchFunction() {
    // console.log(this.oldUserMessageArr);
    if (this.oldUserMessageArr != undefined) {
      let filterData: any = [];
      this.oldUserMessageArr.filter((el: any) => {
        if (el.name.toLowerCase().includes(this.search) || el.email.toLowerCase().includes(this.search)) {
          filterData.push(el);
        }
      });

      this.UserMessageArr = filterData;
      if (this.search == '') {
        this.UserMessageArr = this.oldUserMessageArr;
      }
    }
  }


  getUserMessages() {
    this.loading = true;

    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl + '/getOpenChatAdmin', {}, { headers: header }).subscribe(
      (res: any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.UserMessageArr = res.messages;
        this.oldUserMessageArr = res.messages;
      }, (err => {
        // console.log('err')
        // console.log(err)
        this.loading = false;
        this.router.navigateByUrl('/');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  sendUserMsg() {
    // console.log('send')
    this.loading = true;
    // console.log(this.chat)
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl + '/saveLiveChat', { chat_id: this.selectedUser.id, chat: this.chat, message_type: 'recieved', respond_by_id: localStorage.getItem('support_id') }, { headers: header }).subscribe(
      (res: any) => {
        // console.log('err')
        // console.log(res);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.singleMessageArr = res.messages;
        // console.log(this.singleMessageArr)
        // console.log(this.selectedUser);
        this.chat = '';
      }, (err => {
        // console.log('err')
        // console.log(err)
        this.loading = false;
        // this.router.navigateByUrl('/');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  getUserAllMsg(friend) {
    this.selectedUser = friend;
    console.log(this.selectedUser);
    this.loading = true;

    this.singleMessageArr = [];
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    // console.log(this.router.url);
    if (this.router.url === '/support-agent/dashboard' && this.selectedUser != null) {
      this.http.post(this.global.apiUrl + '/getLiveChat', { chat_id: this.selectedUser.id }, { headers: header }).subscribe(
        (res: any) => {
          // console.log('err')
          // console.log(res);
          this.loading = false;
          this.singleMessageArr = res.messages;
        }, (err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          // this.router.navigateByUrl('/');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error_snackBar']
          });
        })
      )
      // console.log('message getted')
    }
    setInterval(() => {
      if (this.router.url === '/support-agent/dashboard' && this.selectedUser != null) {
        this.http.post(this.global.apiUrl + '/getLiveChat', { chat_id: this.selectedUser.id }, { headers: header }).subscribe(
          (res: any) => {
            // console.log('err')
            // console.log(res);
            this.loading = false;
            this.singleMessageArr = res.messages;
          }, (err => {
            // console.log('err')
            // console.log(err)
            this.loading = false;
            // this.router.navigateByUrl('/');
            this.snackBar.open('Sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['error_snackBar']
            });
          })
        )
        // console.log('message getted')
      }
    }, 30000);

  }

  ChatClose(){
    this.loading = true;
    // console.log(this.chat)
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/closeChat', {chat_id: this.selectedUser.id}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        if(res.status == "Success"){
          this.router.navigateByUrl('/support-agent/dashboard');
          this.snackBar.open('Close your chat  successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        }
       
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    ) 
  }
}

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {


  submitted = false;
  
  emailVerificationForm = this.fb.group({
    verification_code: ['', [Validators.required, Validators.minLength(4),Validators.maxLength(4)]],
    email: localStorage.getItem('user_email')
  });

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private global: GlobalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  get loginControls(){
    return this.emailVerificationForm.controls;
  }

  submitCode(){
    this.submitted = true;
    let data = this.emailVerificationForm.value;
    // console.log(data);
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(data);
    if(this.emailVerificationForm.valid){
      this.http.post(this.global.apiUrl+'/verify_code', data, {headers: header}).subscribe(
        (res : any) => {
          // console.log(res);
          // if(res.message != "Unauthorized"){
          //   // localStorage.setItem('admin_user_id', res.user.id);
          if(res.status != "Failed"){
            this.router.navigateByUrl('/cards');
            this.snackBar.open('Email verified successfully.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
            });
          }else{
              this.snackBar.open(res.message, 'X', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition:'right',
                panelClass: ['error_snackBar']
              });
            }
          },(err => {
            // console.log(err)
            this.snackBar.open('sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
    }
  }

  resendCode(){
    let data = {
      email: localStorage.getItem('user_email')
    };
    // console.log(data);
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(data);
      this.http.post(this.global.apiUrl+'/resend_code', data, {headers: header}).subscribe(
        (res : any) => {
          // console.log(res);
          // if(res.message != "Unauthorized"){
          //   // localStorage.setItem('admin_user_id', res.user.id);
          //   this.router.navigateByUrl('/cards');
          //   this.snackBar.open('Admin login successfully.', 'X', {
          //     duration: 3000,
          //     verticalPosition: 'top',
          //     horizontalPosition:'right',
          //   });
          // }else{
          this.snackBar.open('verification code sent sucessfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right'
          });
          //   }
          },(err => {
            // console.log(err)
            this.snackBar.open('sorry! something went wrong.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
        })
      )
  }

}

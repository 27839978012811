import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareCardLinkModalComponent } from 'src/app/component/share-card-link-modal/share-card-link-modal.component';
// import { VCard } from 'ngx-vcard';


@Component({
  selector: 'app-preview-card',
  templateUrl: './preview-card.component.html',
  styleUrls: ['./preview-card.component.scss']
})
export class PreviewCardComponent implements OnInit {

  @ViewChild('stepper', {static : false}) private stepper: MatStepper;
  @ViewChild('change_photo', {static: false}) changePhotos: ElementRef;
  public id: string;

  infoFormGroup: FormGroup;
  socialFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  templateId;
  templateInfo: any = {};
  color;
  loading;
  
  // public vCard: VCard = {
  //   name: {
  //     firstNames: 'Akmal',
  //     lastNames: 'Ali'
  //   },
  //   telephone:['phone','394993']
  //  };

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute
    ) {

    }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id);
    if(this.id != null){
      console.log('crad id '+ this.id);
      setTimeout(() => {
        this.getCard(this.id);
      }, 3000);
      
    }
    this.infoFormGroup = this.fb.group({
      templateId: '',
      cardTitle: ['', Validators.required],
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'team@goproliftbrand.com',
      phoneNumber: '99122301',
      alternativePhoneNo: '',
      companyName: 'ProLift Rigging Co.',
      jobTitle: 'Affiliate',
      address: 'ProLift Rigging Co. team',
      aboutText: "Thanks for checking out my virtual business card! I’m excited to introduce you to ProLift Rigging Co. because I know you'll enjoy it as much as I have. ProLift Rigging Co. helps me keep track of my prospects, my team, and my time so I can get more accomplished every day. Feel freeto contact me with any questions.",
      phoneTextAllow: true,
      showSaveButton: true,
      showForwardButton: true,
      showInviteCode: true
    });

    this.socialFormGroup = this.fb.group({
      facebook: 'https://www.facebook.com/',
      twitter: 'https://www.twitter.com/',
      youtube: 'https://www.youtube.com/',
      instagram: 'https://www.instagram.com/',
      linkedin: 'https://www.linkedin.com/',
      pinterest: 'https://www.pinterest.com/',
      skypeID: '',
      whatsappID: '',
      snapchat: '',
      lineID: '',
      voxerID: '',
      youtubeVideos: this.fb.array([
        this.fb.group({
          youtubeTitle: '',
          youtubeLink: 'https://www.youtube.com/embed/soXhe4aEsTU'
        })
      ]),
      vimeoVideos: this.fb.array([
        this.fb.group({
          vimeoTitle: '',
          vimeoLink: ''
        })
      ]),
      linkButtons: this.fb.array([
        this.fb.group({
          linkButtonTitle: 'Learn More',
          websiteLink: 'https://goshufflepro.com/'
        })
      ])
    });

    this.fourFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });

    this.templateInfo.infoFormData = this.infoFormGroup.value;
    this.templateInfo.socialFormData = this.socialFormGroup.value;

    this.incrementCardView();

  }

  get youtubeVideosArr() { return <FormArray>this.socialFormGroup.get('youtubeVideos'); }
  get vimeoVideosArr() { return <FormArray>this.socialFormGroup.get('vimeoVideos'); }
  get linkButtonsArr() { return <FormArray>this.socialFormGroup.get('linkButtons'); }

  addYoutubeVideos(){
    let control = <FormArray>this.socialFormGroup.controls.youtubeVideos;
    control.push(
      this.fb.group({
        youtubeTitle: "",
        youtubeLink: ""
      })
    );
  }

  addVimeoVideos(){
    let control = <FormArray>this.socialFormGroup.controls.vimeoVideos;
    control.push(
      this.fb.group({
        vimeoTitle: "",
        vimeoLink: ""
      })
    );
  }

  addButtons(){
    let control = <FormArray>this.socialFormGroup.controls.linkButtons;
    control.push(
      this.fb.group({
        linkButtonTitle: "",
        websiteLink: ""
      })
    );
  }

  removeItem(control, i){
    control.removeAt(i);
    this.templateInfo.socialFormData = this.socialFormGroup.value;
  }

  templateSelcted(){
    if(this.templateId != null){
      this.stepper.next();
    }else{
      this.snackBar.open('Please, select template.', 'X', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition:'right',
        panelClass: ['error_snackBar']
      });
    }
  }

  checkInfoValidation(){
    if(this.infoFormGroup.invalid){
      this.snackBar.open('Please, Fill the mandatory field.', 'X', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition:'right',
        panelClass: ['error_snackBar']
      });
    }else{
      this.stepper.next();
    }
  }

  valueChange(){
    this.templateInfo.infoFormData = this.infoFormGroup.value;
    this.templateInfo.socialFormData = this.socialFormGroup.value;
    // console.log(this.templateInfo);
  }

  changeButtonColor(color){
    this.templateInfo.buttonColor = color;
  }

  // modals
  openSelectTemplateModal(){
    let dialogRef = this.dialog.open(SelectTemplateModalComponent);
    
    dialogRef.afterClosed().subscribe(
      data => {
        this.templateId = data;
      }
    )
  }

  openImageCropperModal(str){
    let dialogRef = this.dialog.open(ImageCropperModalComponent,{
      width: '100vh',
    });

    dialogRef.afterClosed().subscribe(
      image => {
        // console.log(str);
        // console.log(image);
        if(str === 'changePhoto'){
          this.templateInfo.change_photo = image;
          // console.log('changePhoto');
        }else if(str === 'changeLogo'){
          this.templateInfo.change_logo = image;
          // console.log('changeLogo');
        }else{
          this.templateInfo.changeProductImages = image;
          // console.log('else');
        }
        // console.log(this.templateInfo)
      }
    )
  }

  creatCard(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = this.templateInfo;
    data.user_id = localStorage.getItem('user_id');
    data.infoFormData =JSON.stringify(this.infoFormGroup.value);
    data.socialFormData =JSON.stringify(this.socialFormGroup.value);
    data.color =this.color;
    this.http.post(this.global.apiUrl+'/saveCard', data, {headers: header}).subscribe(
      (res : any) => {
        this.router.navigateByUrl('/cards');
          this.snackBar.open('Card created successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getCard(id){
    this.loading = true;
    // console.log('get')
    // console.log(id)
    // let header : HttpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    // });
    
    let data = {
      // user_id: localStorage.getItem('user_id'),
      card_id: id
    };
    this.http.post(this.global.apiUrl+'/getSingleCard', data).subscribe(
      (res : any) => {
        this.loading = false;
        console.log(res)
        this.infoFormGroup.patchValue(res.Card.infoFormData)
        this.socialFormGroup.patchValue(res.Card.socialFormData)
        this.changePhotos = res.Card.change_photo;
        this.templateInfo.infoFormData = res.Card.infoFormData;
        this.templateInfo.socialFormData = res.Card.socialFormData;
        this.templateInfo.change_photo = res.Card.change_photo;
        this.templateInfo.change_logo = res.Card.change_logo;
        this.templateInfo.changeProductImages = JSON.parse(res.Card.changeProductImages);
        this.templateId = res.Card.infoFormData.templateId;
        this.templateInfo.buttonColor = res.Card.colorTheme;
        // console.log('res.Card.inforFormData.templateId');
        // console.log(res.Card.infoFormData);
        // console.log(res.Card.infoFormData.templateId);
      },(err => {
        this.loading = false;
        // console.log(err)
        this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  incrementCardView(){
    // let header : HttpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    // });

    let data = {
      
      card_id : this.id
    
    }

    this.http.post(this.global.apiUrl+'/incrementTotalViews', data).subscribe(
      (res : any) => {
        // this.router.navigateByUrl('/cards');
        // console.log(res)
          this.templateInfo = [];
        },(err => {
          // console.log(err)
          // this.router.navigateByUrl('/cards');
          // this.snackBar.open('Sorry! something went wrong.', 'X', {
          //   duration: 3000,
          //   verticalPosition: 'top',
          //   horizontalPosition:'right',
          //   panelClass: ['error_snackBar']
          // });
      })
    )
  }

}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AdminAuthGuardService {

    constructor(private router: Router) { }
  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
      // console.log(localStorage.getItem('access_token'))
      if(localStorage.getItem('admin_access_token') != null && localStorage.getItem('admin_access_token') != undefined){
        console.log(' admin true')
        return true;
        
      }else{
        // console.log('nul false')
        this.router.navigateByUrl('/admin');
        return false;
      }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AuthGuardService } from './services/auth-guard.service';
import { GlobalService } from './services/global.service';
import { NonSecureGuardService } from './services/non-secure-guard.service';


////////npm 
import { NgxVcardModule } from 'ngx-vcard';

// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { CardsComponent } from './pages/cards/cards.component';
import { GenerateCardComponent } from './pages/generate-card/generate-card.component';
import { SelectTemplateModalComponent } from './component/select-template-modal/select-template-modal.component';
import { AllTemplatesComponent } from './component/all-templates/all-templates.component';

// material design components
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { ImageCropperModalComponent } from './component/image-cropper-modal/image-cropper-modal.component';
import { FooterComponent } from './component/footer/footer.component';
import { RegisterComponent } from './pages/register/register.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { EditCardComponent } from './pages/edit-card/edit-card.component';
import { PreviewCardComponent } from './pages/preview-card/preview-card.component';
import { ShareCardLinkModalComponent } from './component/share-card-link-modal/share-card-link-modal.component';
import { ShareCardComponent } from './pages/share-card/share-card.component';
import { ProfileBasicInformationComponent } from './pages/profile-basic-information/profile-basic-information.component';
import { ProfileWorkSocialComponent } from './pages/profile-work-social/profile-work-social.component';
import { ProfileChangePasswordComponent } from './pages/profile-change-password/profile-change-password.component';
import { UserProfileDetailComponent } from './pages/user-profile-detail/user-profile-detail.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { AddContactComponent } from './component/add-contact/add-contact.component';
import { PackagesComponent } from './pages/packages/packages.component';
import { PersonalContactComponent } from './pages/personal-contact/personal-contact.component';
import { RegisterLearnMoreModalComponent } from './component/register-learn-more-modal/register-learn-more-modal.component';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { AdminAuthGuardService } from './services/admin-auth-guard.service';
import { AdminNonSecuredGuardService } from './services/admin-non-secured-guard.service';
import { AdminHeaderComponent } from './component/admin-header/admin-header.component';
import { AdminUsersControlComponent } from './pages/admin-users-control/admin-users-control.component';
import { AdminEditUserComponent } from './pages/admin-edit-user/admin-edit-user.component';
import { AdminControlPackagesComponent } from './pages/admin-control-packages/admin-control-packages.component';
import { PackageControlFormComponent } from './pages/package-control-form/package-control-form.component';
import { AdminPackageControlFormComponent } from './pages/admin-package-control-form/admin-package-control-form.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { ApplyReferralComponent } from './pages/apply-referral/apply-referral.component';
import { AdminAppliedUserListComponent } from './pages/admin-applied-user-list/admin-applied-user-list.component';
import { SelectPackageModalComponent } from './component/select-package-modal/select-package-modal.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ProductImagesCropperComponent } from './component/product-images-cropper/product-images-cropper.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { HowItsWorksComponent } from './pages/how-its-works/how-its-works.component';
import { BonusAmmountComponent } from './pages/bonus-ammount/bonus-ammount.component';
import { AdminAmountComponent } from './pages/admin-amount/admin-amount.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { VisitorLoginComponent } from './pages/visitor/visitor-login/visitor-login.component';
import { VisitorChatComponent } from './pages/visitor/visitor-chat/visitor-chat.component';
import { LiveChatComponent } from './pages/live-chat/live-chat.component';
import { AdminSupportListComponent } from './pages/admin-support-agents/admin-support-list/admin-support-list.component';
import { AdminSupportAddComponent } from './pages/admin-support-agents/admin-support-add/admin-support-add.component';
import { AdminSupportEditComponent } from './pages/admin-support-agents/admin-support-edit/admin-support-edit.component';
import { AdminChatRecordComponent } from './pages/admin-chat/admin-chat-record/admin-chat-record.component';
import { AdminLiveChatComponent } from './pages/admin-chat/admin-live-chat/admin-live-chat.component';
import { AdminChatViewComponent } from './pages/admin-chat/admin-chat-view/admin-chat-view.component';
import { SupportAgentHomeComponent } from './pages/support-agent-home/support-agent-home.component';
import { SupportHeaderComponent } from './component/support-header/support-header.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { QRCodeModule } from 'angularx-qrcode';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  slideToClickedSlide: true,
  loop: true,
  // simulateTouch: true,
  // spaceBetween: 15,
   navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CardsComponent,
    GenerateCardComponent,
    SelectTemplateModalComponent,
    AllTemplatesComponent,
    ImageCropperModalComponent,
    FooterComponent,
    RegisterComponent,
    ActivityComponent,
    ContactComponent,
    MessagesComponent,
    NotificationsComponent,
    ProfileComponent,
    EditCardComponent,
    PreviewCardComponent,
    ShareCardLinkModalComponent,
    ShareCardComponent,
    ProfileBasicInformationComponent,
    ProfileWorkSocialComponent,
    ProfileChangePasswordComponent,
    UserProfileDetailComponent,
    RequestsComponent,
    AddContactComponent,
    PackagesComponent,
    PersonalContactComponent,
    RegisterLearnMoreModalComponent,
    AdminLoginComponent,
    AdminHomeComponent,
    AdminHeaderComponent,
    AdminUsersControlComponent,
    AdminEditUserComponent,
    AdminControlPackagesComponent,
    PackageControlFormComponent,
    AdminPackageControlFormComponent,
    AdminProfileComponent,
    ApplyReferralComponent,
    AdminAppliedUserListComponent,
    SelectPackageModalComponent,
    ForgotPasswordComponent,
    ProductImagesCropperComponent,
    EmailVerificationComponent,
    HowItsWorksComponent,
    BonusAmmountComponent,
    AdminAmountComponent,
    PrivacyPolicyComponent,
    VisitorLoginComponent,
    VisitorChatComponent,
    LiveChatComponent,
    AdminSupportListComponent,
    AdminSupportAddComponent,
    AdminSupportEditComponent,
    AdminChatRecordComponent,
    AdminLiveChatComponent,
    AdminChatViewComponent,
    SupportAgentHomeComponent,
    SupportHeaderComponent,
    LandingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    ColorPickerModule,
    NgxEditorModule,
    ImageCropperModule,
    MatSliderModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatCardModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatTableModule,
    MatCheckboxModule,
    NgxVcardModule,
    QRCodeModule
  ],
  entryComponents: [
    SelectTemplateModalComponent,
    ImageCropperModalComponent,
    ShareCardLinkModalComponent,
    AddContactComponent,
    RegisterLearnMoreModalComponent,
    SelectPackageModalComponent,
    ProductImagesCropperComponent
  ],
  providers: [
    AuthGuardService,
    NonSecureGuardService,
    AdminAuthGuardService,
    AdminNonSecuredGuardService,
    GlobalService,
    MatDatepickerModule,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MatStepper,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

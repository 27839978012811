import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImageFile: File | null = null; 
  croppedImageURL: SafeUrl = ''; 
  croppedImage: string = '';
  
  constructor(private dialogRef: MatDialogRef<ImageCropperModalComponent>,
    private snackBar: MatSnackBar, private sanitizer: DomSanitizer) { }
  
  ngOnInit() {
  }
  
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log('event => ', event);
    
    // Set the new width and height for the cropped image (optional)
    event.width = 800;
    event.height = 800;

    this.croppedImage = event.base64;

    // Convert base64 to File (Blob)
    this.croppedImageFile = this.base64ToFile(event.base64, 'cropped-image.png');

  }


  loadImageFailed() {
    this.dialogRef.close(null);
    this.snackBar.open('Sorry, something went wrong.Please Try Again.', 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition:'right',
      panelClass: ['error_snackBar']
    });
  }

  saveImage(){
    const data = {
      croppedImage: this.croppedImage,
      croppedImageFile: this.croppedImageFile

    }
    this.dialogRef.close(data);
  }

  // Helper function to convert base64 to File
  base64ToFile(base64: string, filename: string): File {
    const byteString = atob(base64.split(',')[1]); // Decode base64 string
    const arrayBuffer = new ArrayBuffer(byteString.length); // Create an ArrayBuffer
    const uint8Array = new Uint8Array(arrayBuffer);

    // Convert byteString to Uint8Array
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a Blob with the appropriate mime type
    const blob = new Blob([uint8Array], { type: 'image/png' });

    // Convert Blob to File
    return new File([blob], filename, { type: 'image/png' });
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {


  
  user: any;
  loading = false;
  search;
  requestsRecievedByMe : any = [];
  requestsSentByMe: any = [];

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserData();
    this.getUserContacts();
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.user = res.Users;
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  getUserContacts(){
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/myRequests', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.requestsSentByMe = res.requestsSentByMe;
        this.requestsRecievedByMe = res.requestsRecievedByMe;
        // this.router.navigateByUrl('/cards');
        //   this.snackBar.open('Card created successfully.', 'X', {
        //     duration: 3000,
        //     verticalPosition: 'top',
        //     horizontalPosition:'right',
        //   });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  acceptRequest(id){
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl+'/acceptRequest', {request_id: id}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/cards');
          this.snackBar.open('Request accepted successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
          this.getUserContacts();
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-user-profile-detail',
  templateUrl: './user-profile-detail.component.html',
  styleUrls: ['./user-profile-detail.component.scss']
})
export class UserProfileDetailComponent implements OnInit {
  user: any;
  friend: any;
  loading = false;
  requestStatus;

  constructor(private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService) {
  }

  ngOnInit() {
    // console.log('on')
    this.route.params.subscribe(params => {
      // each time the search data is change you'll get this running
      //Do what ever you need to refresh your search page
      // console.log(params);
      // console.log('New route params');
      this.getUserData(params.id);
    });
  }

  getUserData(id){
    // console.log('user')
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl+'/userData', {user_id:localStorage.getItem('user_id') , friend_id : id,}, {headers: header}).subscribe(
      (res : any) => {
        this.loading = false;
        // console.log(res);
        this.user = res.Users;
        this.friend = res.friend;
        this.requestStatus = res.contacted;
      },(err => {
          this.loading = false;
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sentRequest(id){
    this.loading = true;

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl+'/sendRequest',  {user_id:localStorage.getItem('user_id') , friend_id : id,}, {headers: header}).subscribe(
      (res : any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/cards');
          this.snackBar.open('Request sent successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
          this.route.params.subscribe(params => {
            // each time the search data is change you'll get this running
            //Do what ever you need to refresh your search page
            // console.log(params);
            // console.log('New route params');
            this.getUserData(params.id);
          });
        },(err => {
          // console.log('err')
          // console.log(err)
          this.loading = false;
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sendMessageUserObj(user){
    // console.log(this.global.sentMessageUserObj.find(element => element.id != user.id));
    if(this.global.sentMessageUserObj.length > 0){
      if(this.global.sentMessageUserObj.find(element => element.id != user.id)){
        this.global.sentMessageUserObj.push(user);
      }
    }else{
      this.global.sentMessageUserObj.push(user);
    }
    this.router.navigateByUrl('/messages');
  }

}

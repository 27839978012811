import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-admin-support-edit',
  templateUrl: './admin-support-edit.component.html',
  styleUrls: ['./admin-support-edit.component.scss']
})
export class AdminSupportEditComponent implements OnInit {
  id;
  loading;
  editData: any;

  supportAgent: FormGroup;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.editData = this.global.getExtras;
    console.log(this.editData);
    this.id = this.editData.id;
    this.supportAgent = new FormGroup({
      name: new FormControl(this.editData.name, {
        updateOn: 'blur'
      }),
      email: new FormControl(this.editData.email, {
        updateOn: 'blur'
      })
    });
    // this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id);

  }

  submitSupportAgent() {

    this.supportAgent.value.id = this.id;
    console.log(this.supportAgent.value)
    // console.log(this.basicInfoForm.value)
    this.loading = true;

    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });


    // this.basicInfoForm.value

    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl + '/updateSupportAgent', this.supportAgent.value, { headers: header }).subscribe(
      (res: any) => {
        // console.log('err')
        // console.log(res);
        this.loading = false;
        this.router.navigateByUrl('/admin/admin-support-agent-list');
        this.snackBar.open('Support Agent Update successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
        });
      }, (err => {
        // console.log('err')
        // console.log(err)
        this.loading = false;
        // this.router.navigateByUrl('/cards');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

}

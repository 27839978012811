import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CardsComponent } from './pages/cards/cards.component';
import { GenerateCardComponent } from './pages/generate-card/generate-card.component';
import { RegisterComponent } from './pages/register/register.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NonSecureGuardService } from './services/non-secure-guard.service';
import { EditCardComponent } from './pages/edit-card/edit-card.component';
import { PreviewCardComponent } from './pages/preview-card/preview-card.component';
import { ShareCardComponent } from './pages/share-card/share-card.component';
import { ProfileBasicInformationComponent } from './pages/profile-basic-information/profile-basic-information.component';
import { ProfileWorkSocialComponent } from './pages/profile-work-social/profile-work-social.component';
import { ProfileChangePasswordComponent } from './pages/profile-change-password/profile-change-password.component';
import { UserProfileDetailComponent } from './pages/user-profile-detail/user-profile-detail.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { PackagesComponent } from './pages/packages/packages.component';
import { PersonalContactComponent } from './pages/personal-contact/personal-contact.component';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { AdminNonSecuredGuardService } from './services/admin-non-secured-guard.service';
import { AdminAuthGuardService } from './services/admin-auth-guard.service';
import { AdminUsersControlComponent } from './pages/admin-users-control/admin-users-control.component';
import { AdminEditUserComponent } from './pages/admin-edit-user/admin-edit-user.component';
import { AdminControlPackagesComponent } from './pages/admin-control-packages/admin-control-packages.component';
import { AdminPackageControlFormComponent } from './pages/admin-package-control-form/admin-package-control-form.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { ApplyReferralComponent } from './pages/apply-referral/apply-referral.component';
import { AdminAppliedUserListComponent } from './pages/admin-applied-user-list/admin-applied-user-list.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { HowItsWorksComponent } from './pages/how-its-works/how-its-works.component';
import { BonusAmmountComponent } from './pages/bonus-ammount/bonus-ammount.component';
import { AdminAmountComponent } from './pages/admin-amount/admin-amount.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { VisitorLoginComponent } from './pages/visitor/visitor-login/visitor-login.component';
import { VisitorChatComponent } from './pages/visitor/visitor-chat/visitor-chat.component';
import { LiveChatComponent } from './pages/live-chat/live-chat.component';
import { AdminSupportListComponent } from './pages/admin-support-agents/admin-support-list/admin-support-list.component';
import { AdminSupportAddComponent } from './pages/admin-support-agents/admin-support-add/admin-support-add.component';
import { AdminSupportEditComponent } from './pages/admin-support-agents/admin-support-edit/admin-support-edit.component';
import { AdminChatRecordComponent } from './pages/admin-chat/admin-chat-record/admin-chat-record.component';
import { AdminLiveChatComponent } from './pages/admin-chat/admin-live-chat/admin-live-chat.component';
import { AdminChatViewComponent } from './pages/admin-chat/admin-chat-view/admin-chat-view.component';
import { SupportAgentHomeComponent } from './pages/support-agent-home/support-agent-home.component';
import { SupportAuthGuardService } from './services/support-auth-guard.service';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';


const routes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  /* { path: '',   redirectTo: '/landing', pathMatch: 'full' },
  {
    path: 'landing',
    component: LandingPageComponent
  }, */
  {
    path: 'login',
    component: RegisterComponent,
    canActivate: [NonSecureGuardService]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonSecureGuardService]
  },
  {
    path: 'cards',
    component: CardsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'create-card',
    component: GenerateCardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'edit-card/:id',
    component: EditCardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'cards/preview-card/:id',
    component: PreviewCardComponent
  },
  {
    path: 'cards/share-card/:id',
    component: ShareCardComponent
  },
 /*  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [AuthGuardService]
  }, */
  /* {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuardService]
  }, */
  {
    path: 'activities',
    component: ActivityComponent,
    canActivate: [AuthGuardService]
  },
  /* {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  }, */
  {
    path: 'user/:id',
    component: UserProfileDetailComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile/basic-information',
    component: ProfileBasicInformationComponent,
    canActivate: [AuthGuardService]
  },
  /* {
    path: 'profile/work-and-social',
    component: ProfileWorkSocialComponent,
    canActivate: [AuthGuardService]
  }, */
  {
    path: 'profile/change-password',
    component: ProfileChangePasswordComponent,
    canActivate: [AuthGuardService]
  },
  /* {
    path: 'profile/apply-for-referral',
    component: ApplyReferralComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile/bonus-ammount',
    component: BonusAmmountComponent,
    canActivate: [AuthGuardService]
  }, */
  /* {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'requests',
    component: RequestsComponent,
    canActivate: [AuthGuardService]
  }, */
  {
    path: 'personal-contact/:id',
    component: PersonalContactComponent,
    canActivate: [AuthGuardService]
  },
  /* {
    path: 'admin',
    component: AdminLoginComponent,
    canActivate: [AdminNonSecuredGuardService]
  },
  {
    path: 'admin/dashboard',
    component: AdminHomeComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'support-agent/dashboard',
    component: SupportAgentHomeComponent,
    canActivate: [SupportAuthGuardService]
  },
  {
    path: 'admin/users',
    component: AdminUsersControlComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/users/:id',
    component: AdminEditUserComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/packages',
    component: AdminControlPackagesComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/packages',
    component: AdminControlPackagesComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/package',
    component: AdminPackageControlFormComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/edit-package/:id',
    component: AdminPackageControlFormComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/profile',
    component: AdminProfileComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/referral-applied-user',
    component: AdminAppliedUserListComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-amount',
    component: AdminAmountComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-support-agent-list',
    component: AdminSupportListComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-support-agent-add',
    component: AdminSupportAddComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-support-agent-edit',
    component: AdminSupportEditComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-chat-record',
    component: AdminChatRecordComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-live-chat',
    component: AdminLiveChatComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'admin/admin-chat-view',
    component: AdminChatViewComponent,
    canActivate: [AdminAuthGuardService]
  }, */
  // {
  //   path: 'pakages',
  //   component: PackagesComponent,
  //   canActivate: [AuthGuardService]
  // },
  {
    path: 'email-verification',
    component: EmailVerificationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'how-it-works',
    component: HowItsWorksComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [NonSecureGuardService]
  },
  /* {
    path: 'visitor-login',
    component: VisitorLoginComponent,
    canActivate: [NonSecureGuardService]
  },
  {
    path: 'visitor-chat',
    component: VisitorChatComponent,
    canActivate: [NonSecureGuardService]
  },
  {
    path: 'live-chat',
    component: LiveChatComponent,
    canActivate: [AuthGuardService]
  }, */
  { path: '**',component: RegisterComponent,  canActivate: [NonSecureGuardService] },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-apply-referral',
  templateUrl: './apply-referral.component.html',
  styleUrls: ['./apply-referral.component.scss']
})
export class ApplyReferralComponent implements OnInit {

  user: any;
  loading = false;
  contacts : any = [];


  referalForm = this.fb.group({
    user_id:[''],
    payment_id: [''],
    payment_type: ['']
  });
  payment_id:any;
  payment_type:any;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserData();
  }

  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        localStorage.setItem('invite_Code',res.Users.refer_code);
        this.user = res;
        this.payment_id = res.Users.payment_id;
        this.payment_type = res.Users.payment_method;
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  sendRequest(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/applyForReferral', {user_id: localStorage.getItem('user_id')} , {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        this.snackBar.open('Referral request sent successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  savePaymentInfo(){
    console.log(this.payment_id);
    console.log(this.payment_type);
    let data = {
      user_id:localStorage.getItem('user_id'),
      payment_id:this.payment_id,
      payment_type:this.payment_type
    }
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    
    // this.referalForm.value.user_id = localStorage.getItem('user_id')
    // console.log(this.referalForm.value)
    this.http.post(this.global.apiUrl+'/savePaymentInfo', data , {headers: header}).subscribe(
      (res : any) => {
        this.snackBar.open('Payment Info Save successfully.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition:'right'
        });
        },(err => {
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AdminNonSecuredGuardService {

	constructor(private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (localStorage.getItem('admin_access_token') == null && localStorage.getItem('admin_access_token') == undefined) {
			// console.log('nul1 true')
			return true;
		}
		else {
			if (localStorage.getItem('user_type') == 'admin') {
				console.log('admin');
				this.router.navigateByUrl('/admin/dashboard');
			}
			else {
				console.log('support');
				this.router.navigateByUrl('/support-agent/dashboard');
			}

			// console.log('nul1 false')
			return false;
		}
	}
}
